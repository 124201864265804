import React, {useState, useRef} from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import emailjs from 'emailjs-com';

const ContactForm = (props) => {
    const [success, setSuccess] = useState(false);
    const form = useRef();


    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .email("Please enter a valid email")
            .required("Email is required"),
        name: Yup.string()
            .required("Name is required")
            .min(2, "Name too short! Must be at least 3 characters."),
        message: Yup.string(),
    });

    const handleSubmit = (values) => {
        emailjs.sendForm('service_1s0w3qv', 'template_fli7l0o', form.current, 'Y1Uv-7N2-8Rpsg9Wh')
        .then((result) => {
            setSuccess(true);
            setTimeout( () => {
                setSuccess(false);
            },4000);
        }, (error) => {
            console.log('error--: '+error.text);
        });
    };

    return(
        <div className='contact_form' id='contactUS'>
            <div className="container_form">
                <div className="title">
                    If you have an idea, drop as a line.
                    <p>
                        If the question is "Is it possible to...?" our answer will most likely be yes. <br/>
                        So lets chat and make things happen.
                    </p>
                </div>
                <Formik
                    initialValues={{
                        email: "",
                        name: "",
                        message: "",
                    }}
                    onSubmit={(values, errors) => {
                        handleSubmit(values);
                        setTimeout( () => {
                            errors.resetForm();
                        },4000);
                    }}
                    validationSchema={validationSchema}
                >
                    {({ values, errors, dirty, isValid, isSubmitting, handleSubmit, handleChange }) => {
                        return (
                            <form onSubmit={handleSubmit} ref={form} className={`${errors.email || errors.name || errors.message? "error" : ""}`}>
                                <div className="field_box half">
                                    <label>Name*</label>
                                    <input type="text" name="name" placeholder="Write your name" value={values.name} onChange={handleChange("name")} className={`${errors.name? "error" : ""}`}/>
                                </div>
                                <div className="field_box half">
                                    <label>Email*</label>
                                    <input type="text" name="email" placeholder="Write your email" value={values.email} onChange={handleChange("email")} className={`${errors.email? "error" : ""}`}/>
                                </div>
                                <div className="field_box">
                                    <label>Tell us your idea</label>
                                    <textarea name="message" rows="3" placeholder="Write your message" value={values.message} onChange={handleChange("message")} className={`${errors.message? "error" : ""}`}/>
                                </div>
                                <div className="instruction">* Required fields.</div>
                                <div className={ `success ${success? 'active' :""}`}>We've received your message and will be in touch soon.</div>
                                <button type="submit" disabled={!(isValid && dirty)}>Send</button>
                            </form>
                        );
                    }}
                </Formik>
            </div>
        </div>
    );
}

export default ContactForm;