import React, {useEffect} from 'react';

import Header from '../components/header'
import Footer from '../components/footer'
import BannerIntern from '../components/bannerIntern'
import InfoRowRight from '../components/infoRowRight'
import InfoRowLeft from '../components/infoRowLeft'

const width = window.innerWidth;
const breakpoint = 620;

function Netflix() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className="App">
      <Header
        logo={require("../assets/logo.png")}
      />
      <BannerIntern
        className="netflix"
        bgBanner={width < breakpoint ? require("../assets/banner/banner-netflix-mobile.png") : require("../assets/banner/banner-netflix-interna.png")}
        logoBanner={require("../assets/logoBanner/brand-netflix.png")}
      />
      <div className="section-1 mt-5 mb-5">
        <div className="container-fluid">

          <InfoRowRight
            infoImgR={require("../assets/img-1-contenido-netflix.png")}
            infoTitleR="Problem"
            infoTextR="Allow Netflix to know more about the perception of their publications on their social networks (specifically Instagram) and provide valuable insights not provided by the standard analytics supplied by the social network."
          />

          <InfoRowLeft
            infoImgL={require("../assets/img-2-contenido-netflix.png")}
            infoTitleL="Solution"
            infoTextL="We developed an artificial intelligence algorithm that is responsible for scanning Netflix Latam Instagram account. The system is responsible for pulling all the posts published by the brand and deliver relevant information on engagement, elements found within the images such as: hair, glasses, shades, colors, etc. and also to detect the talent (actor) that is in the image, automatically.<br /><br />
            This information is then displayed in a way that allows the teams from both brand an agency to generate actionable insights for their social content teams."
          />

          <InfoRowRight
            infoImgR={require("../assets/img-3-contenido-netflix.png")}
            infoTitleR="Result"
            infoTextR="The first phase of the project launched early 2021, which has analyzed thousands of photographs and generates insights based on brand content (brand-centric).<br /><br />
            The second phase of the project due to launch mid-2022, which will shift its focus to analyzing user behavior/ interaction with the content (user-centric)."
          />

        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Netflix;
